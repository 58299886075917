
export const data = [
    { 
        title: 'Regional',
        overlaySrc: 'bkg--site-location-regional.png',
        overlayNode: null
    },
    { 
        title: 'Local',
        overlaySrc: 'bkg--site-location-local.png',
        overlayNode: null
    }
];
import React from 'react';
import SEO from '../components/seo/SEO';
import ViewSiteLocation from '../components/siteLocation/ViewSiteLocation';


export default function SiteLocation () { 

  return (
    <>
      <SEO title='Site Location' />
      <ViewSiteLocation />
    </>
  );

}

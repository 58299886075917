import React, { useRef, useEffect } from 'react'
import Img from 'gatsby-image';
import { utilAnimate } from '../../utils/utilAnimate';
import styles from './stylesSiteLocation.module.scss';


export default function ViewSiteLocationOverlay ( { item, currItem, selectItem } ) {
	
	const containerRef = useRef(null);
	useEffect(() => {
        item.title === currItem ? utilAnimate.fadeIn(containerRef.current) : utilAnimate.fadeOut(containerRef.current);
    });

	return (
		<div ref={ containerRef } className={ styles.overlay } >
			<Img fixed={ item.overlayNode.childImageSharp.fixed } key={ item.overlayNode.id } alt={ item.title } />
			{ item.title === 'Regional' &&
			<button className={ styles.btnLocal } onClick={ () => selectItem('Local') } aria-label='To local button'>View local map</button>
			}
		</div>
	);

}

import React from 'react'
import styles from './stylesSiteLocation.module.scss';


export default function ButtonSiteLocationItem ( { item, currItem, selectItem } ) {
  
	return (
    	<button 
      		className={ item.title === currItem ? styles.btn__active : styles.btn } 
     	 	onClick={ () => selectItem(item.title) } 
    	>
      	{/* <img src={ iconSrc } width='29' height='29' alt='precinct icon' /> */}
      	{ item.title }
    	</button>
  	);

}
